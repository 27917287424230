import React, {useState} from 'react';
import './Login.css';
import {useNavigate} from 'react-router-dom';
import axios from 'axios';
import {SERVER_ADDRESS} from "../setupInfo";
import PasswordInput from './Components/PasswordInput';


function Footer() {
    return (
        <footer className="App-footer">
            <p>© 2024 Emotional Support</p>
        </footer>
    );
}

const securityQuestions = [
    "What was the name of your first pet?",
    "What is your mother's maiden name?",
    "What was the name of your elementary school?",
    "In what city were you born?"
];

const PasswordStrength = {
    WEAK: {
        message: 'Your password is weak. Try adding more characters and mixing letters, numbers, and special symbols.',
        color: 'red'
    },
    FAIR: {
        message: 'Your password is fair. It can be stronger by adding special characters and ensuring it is at least 12 characters.',
        color: 'orange'
    },
    STRONG: {
        message: 'Your password is strong and secure.',
        color: 'green'
    }
};

export function Login() {
    localStorage.setItem("authToken", NaN);
    localStorage.setItem("role", NaN);
    const [isLogin, setIsLogin] = useState(true);
    const [password, setPassword] = useState('');
    const [confirmationPassword, setConfirmationPassword] = useState('');
    const [email, setEmail] = useState('');
    const [dateOfBirth, setDob] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [username, setUsername] = useState('');
    const [gender, setGender] = useState('');
    const [securityQuestion, setSecurityQuestion] = useState('');
    const [securityAnswer, setSecurityAnswer] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmationPassword, setShowConfirmationPassword] = useState(false);
    const [showFormValidWarning, setShowFormValidWarning] = useState(false);

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    const toggleConfirmationPasswordVisibility = () => {
        setShowConfirmationPassword(!showConfirmationPassword);
    };

    let navigate = useNavigate();

    const isFormEmpty = () => {
        return !firstName || !lastName || !dateOfBirth || !email || !username || !password || !confirmationPassword
            || !securityQuestion || !securityAnswer;
    }

    const validateEmail = () => {
        const re = /^[^@\s]+@[^@\s]+\.[^@\s]+$/;
        return re.test(email.toLowerCase()) || email.length === 0;
    }

    const isPasswordValid = () => {
        return password.length >= 8 || password.length === 0;
    }

    const calculatePasswordStrength = () => {
        const hasUpperCase = /[A-Z]/.test(password);
        const hasLowerCase = /[a-z]/.test(password);
        const hasNumbers = /\d/.test(password);
        const hasSpecialChars = /[!@#$%^&*(),.?":{}|<>]/.test(password);

        if (hasUpperCase && hasLowerCase && hasNumbers && hasSpecialChars) {
            return PasswordStrength.STRONG;
        } else if (hasUpperCase && hasLowerCase && hasNumbers && password.length >= 12) {
            return PasswordStrength.FAIR;
        } else {
            return PasswordStrength.WEAK;
        }
    }

    const isPasswordSame = () => {
        return password === confirmationPassword || confirmationPassword.length === 0;
    }

    const isDOBValid = () => {
        const age = new Date().getFullYear() - new Date(dateOfBirth).getFullYear();
        return age >= 13 || dateOfBirth.length === 0;
    }

    const isUsernameValid = () => {
        const re = /[0-9a-zA-Z]/;
        return (username.length > 4 && re.test(username)) || confirmationPassword.length === 0;
    }

    const isNewUserFormValid = () => {
        return isPasswordValid() && isPasswordSame() && validateEmail() && isDOBValid() && !isFormEmpty() && isUsernameValid();
    }

    const isLoginFormValid = () => {
        return isPasswordValid() && isUsernameValid();
    }

    const onUserLoginSubmit = async (event) => {
        event.preventDefault();

        if (!isLoginFormValid()) {
            setShowFormValidWarning(true);
            return;
        } else {
            setShowFormValidWarning(false);
        }

        const userLogin = {
            username,
            password
        };

        try {
            const response = await axios.post(SERVER_ADDRESS + '/auth/login', userLogin);
            if (response.status === 200) {
                const authToken = response.data.token;
                localStorage.setItem('authToken', authToken); // Save token to local storage
                localStorage.setItem('role', 'patient')
                console.log('User login successfully')
                console.log(response.data);
                navigate('/dashboard');
            }
        } catch (error) {
            console.error('Failed to login', error);
        }
    };

    const onNewUserFormSubmit = async (event) => {
        event.preventDefault();

        const userRegistration = {
            email,
            firstName,
            lastName,
            username,
            dateOfBirth,
            gender,
            password,
            securityQuestion,
            securityAnswer
        };

        try {
            const response = await axios.post(SERVER_ADDRESS + '/users', userRegistration);

            if (response.status === 200) {
                console.log('User registered successfully')
                const login_response = await axios.post(SERVER_ADDRESS + '/auth/login', {username, password});
                if (login_response.status === 200) {
                    const authToken = login_response.data['token'];
                    localStorage.setItem('authToken', authToken); // Save token to local storage
                    localStorage.setItem('role', 'patient')
                    console.log(response.data)
                    navigate('/dashboard');
                }
            }
        } catch (error) {
            console.error('Error during registration', error);
        }
    };

    return (
        <div className="App">
            {isLogin ? (
                <div className="form-container">
                    <div className="title">
                        <h2>Login</h2>
                        <h4>to get started</h4>
                    </div>
                    <form onSubmit={onUserLoginSubmit}>
                        <input type="text" placeholder="Username" onChange={e => setUsername(e.target.value)}/>
                        <PasswordInput
                            value={password}
                            placeholder={"Password"}
                            onChange={e => setPassword(e.target.value)}
                            isValid={isPasswordValid()}
                            showPassword={showPassword}
                            togglePasswordVisibility={togglePasswordVisibility}
                        />
                        <button
                            id="forgot-password-button"
                            className="text-button"
                            onClick={() => navigate('/forgot-password', {state: {from: 'user'}})}
                        >
                            Forgot Password?
                        </button>

                        <div>
                            {showFormValidWarning &&
                                <p className="warning-message">Please fill in all the required fields.</p>}
                            <button type="submit">Continue</button>
                        </div>
                    </form>

                    <button className="text-button signup-button" onClick={() => setIsLogin(false)}>
                        New user? Register!
                    </button>

                    <button className="text-button back-to-role" onClick={() => navigate("/")}>
                        Back to role choice
                    </button>
                </div>
            ) : (
                <div className="form-container signup" style={{textAlign: "left"}}>
                    <h2>Sign Up</h2>
                    <form onSubmit={onNewUserFormSubmit}>
                        <div className="horizontal-form">
                            <div className="horizontal-column first-column">
                                <input
                                    type="text"
                                    placeholder="Name"
                                    onChange={(e) => setFirstName(e.target.value)}
                                />
                            </div>
                            <div className="horizontal-column">
                                <input
                                    type="text"
                                    placeholder="Surname"
                                    onChange={(e) => setLastName(e.target.value)}
                                />
                            </div>
                        </div>
                        <div className="horizontal-form">
                            <div className="horizontal-column first-column">
                                <input
                                    type="text"
                                    placeholder="Username"
                                    onChange={(e) => setUsername(e.target.value)}
                                />
                                {!isUsernameValid() && (
                                    <p className="warning-message">
                                        Has to be 5+ symbols long, no special characters
                                    </p>
                                )}
                            </div>
                            <div className="horizontal-column">
                                <input
                                    type="date"
                                    placeholder="1990-01-01"
                                    onChange={(e) => setDob(e.target.value)}
                                    style={isDOBValid() ? {} : {border: "1px solid lightcoral"}}
                                    className="custom-date-picker"
                                />
                                {!isDOBValid() && (
                                    <p className="warning-message">
                                        Must be 13+ to register
                                    </p>
                                )}
                            </div>
                        </div>
                        <form class="gender-select">
                            <label class="gender-option">
                                <input type="radio" name="gender" value="male"
                                       onChange={(e) => setGender(e.target.value)}/>
                                <span class="checkmark"></span> Male
                            </label>
                            <label class="gender-option">
                                <input type="radio" name="gender" value="female"
                                       onChange={(e) => setGender(e.target.value)}/>
                                <span class="checkmark"></span> Female
                            </label>
                            <label class="gender-option">
                                <input type="radio" name="gender" value="other"
                                       onChange={(e) => setGender(e.target.value)}/>
                                <span class="checkmark"></span> Other
                            </label>
                        </form>

                        <input
                            type="email"
                            placeholder="Email"
                            onChange={(e) => setEmail(e.target.value)}
                            style={validateEmail() ? {} : {border: "1px solid lightcoral"}}
                        />
                        {!validateEmail() && (
                            <p className="warning-message">Please enter a valid Email.</p>
                        )}

                        <div className="horizontal-form">
                            <div className="horizontal-column first-column">
                                <PasswordInput
                                    value={password}
                                    placeholder={"Password"}
                                    onChange={e => setPassword(e.target.value)}
                                    isValid={isPasswordValid()}
                                    showPassword={showPassword}
                                    togglePasswordVisibility={togglePasswordVisibility}
                                />
                                {isPasswordValid() && password.length !== 0 && (() => {
                                    const strength = calculatePasswordStrength(password);
                                    return (
                                        <p className="warning-message" style={{color: strength.color}}>
                                            {strength.message}
                                        </p>
                                    );
                                })()}
                                {!isPasswordValid() && (
                                    <p className="warning-message">
                                        Password must be at least 8 characters long.
                                    </p>
                                )}
                            </div>
                            <div className="horizontal-column">
                                <PasswordInput
                                    value={confirmationPassword}
                                    placeholder={"Confirm password"}
                                    onChange={e => setConfirmationPassword(e.target.value)}
                                    isValid={isPasswordSame()}
                                    showPassword={showConfirmationPassword}
                                    togglePasswordVisibility={toggleConfirmationPasswordVisibility}
                                />
                            </div>
                        </div>


                        <select
                            onChange={(e) => setSecurityQuestion(e.target.value)}
                            value={securityQuestion}
                            className="security-question-dropdown"
                        >
                            <option value="" disabled>Select a security question</option>
                            {securityQuestions.map((question, index) => (
                                <option key={index} value={question}>{question}</option>
                            ))}
                        </select>

                        <input
                            type="text"
                            placeholder="Answer to selected security question"
                            onChange={(e) => setSecurityAnswer(e.target.value)}
                        />

                        {!isPasswordSame() && (
                            <p className="warning-message">
                                Passwords must match the confirmation password.
                            </p>
                        )}

                        <div>
                            {showFormValidWarning && (
                                <p className="warning-message">
                                    Please fill in all the required fields.
                                </p>
                            )}
                            <button type="submit" disabled={!isNewUserFormValid()}>
                                Sign Up
                            </button>
                        </div>
                    </form>
                    <button
                        className="text-button signup-button"
                        onClick={() => setIsLogin(true)}
                    >
                        Back to Login
                    </button>
                    <button className="text-button back-to-role" onClick={() => navigate("/")}>
                        Back to role choice
                    </button>
                </div>
            )}
            <Footer/>
        </div>
    );
}

export function isLoggedIn() {
    localStorage.getItem('authToken');
    return localStorage.getItem('authToken') !== null;
}
